<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div class="production_page">
        <div class="production_description_area" v-if="userData && userData.city && parseInt(tile_id) === userData.city.tile_id">
            <div v-if="userData.city.user_id === userData.id">
                <div v-show="isTutorialLevelZero" class="backgroundTutorial">
                    <div class="tutorial">
                        <p v-html="$t('app.tutorial_zero_produkcja1')"></p>
                        <div v-if="userData.producer === 1">
                            {{ $t('app.products_producer1') }}.
                        </div>
                        <div v-else-if="userData.producer === 2">
                            {{ $t('app.products_producer2') }}.
                        </div>
                        <div v-else-if="userData.producer === 3">
                            {{ $t('app.products_producer3') }}.
                        </div>
                        <p v-html="$t('app.tutorial_zero_produkcja2')"></p>
                        <button @click="continueGame">{{ $t('app.gram_dalej') }}</button>
                    </div>
                </div>
                <div v-show="!isTutorialLevelZero">
                    <div v-if="userData.city.citizens > 0">
                        <div>
                            <p v-html="$t('app.opis_produkcja1', { citizens: userData.city.citizens, work_fields: userData.city.work_fields })"></p>
                            <div v-if="userData.city.workingCitizens">
                                <template v-for="singleWork in userData.city.workers" :key="singleWork.id">
                                    {{ $t('app.przy_zadaniu') }} <strong>{{ $t('products.product_' + singleWork.product_id) }}</strong> {{ $t('app.pracuje') }} {{ singleWork.citizens }} {{ $t('app.osob') }}.<br>
                                    <TimerComponent v-if="singleWork.end_work_time" :endTime="singleWork.end_work_time" action="stopProduction" :oData="singleWork"></TimerComponent><br>
                                    {{ $t('app.produkcja') }}: {{ singleWork.effect }} szt.
                                    <br>
                                </template>
                            </div>
                            <div v-else-if="userData.city.workingCitizens == 0">
                                <p v-html="$t('app.zaden_z_nich_nie_pracuje')"></p>
                                <br>
                            </div>
                            <div v-if="userData.city.citizens > userData.city.workingCitizens">
                                <WorkersForm
                                    :userData="userData"
                                    :maxWorkers="maxWorkers"
                                    @productionUpdated="fetchData"/>
                            </div>
                            <div v-else>
                                <br><br>
                                <p v-html="$t('app.opis_produkcja2')"></p>
                            </div>
                            <br><br>
                        </div>
                    </div>
                    <div v-else>
                        <p v-html="$t('app.opis_produkcja2')"></p>
                    </div>
                </div>
            </div>
            <div v-else>
                Wizyta u gubernatora obcego miasta
            </div>
        </div>
        <div v-else>
            To nie twoje miasto!
        </div>
        <div class="toCity" @click="goToCity(tile_id)">&larr; {{ $t('app.do_miasta') }}</div>
    </div>
</template>
<script>

import API from '@/utils/axios';
import WorkersForm from "@/components/WorkersForm.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import TimerComponent from '@/components/TimerComponent.vue';
import {usePiniaStore} from "@/store/store";

export default {
    data() {
        return {
            citizens: 0,
            product_id: null,
            tile_id: this.$route.params.tile_id,
            userData: {},
            isTutorialLevelZero: true,
            maxWorkers: 0,
            isLoading: true
        };
    },
    components: {
        WorkersForm,
        LoadingComponent,
        TimerComponent
    },
    methods: {
        continueGame() {

            API.put(`/users`, {
                tutorial_level: 1
            })
            .then(response => {
                console.log('komunikat po update', response.data.message);
                this.store.setUserData({ tutorial_level: 1 });
                this.isTutorialLevelZero = false;
            })
            .catch(error => {
                console.error('Wystąpił błąd podczas aktualizacji poziomu tutoriala:', error);
            });
        },
        getProductRange(producer) {
            switch (producer) {
                case 1:
                    return [1, 2, 3, 4, 5, 6, 7, 22];
                case 2:
                    return [8, 9, 10, 11, 12, 13, 14, 22];
                case 3:
                    return [15, 16, 17, 18, 19, 20, 21, 22];
                default:
                    return [];
            }
        },
        goToCity(tile_id) {
            // Przekieruj użytkownika na mapę
            this.$router.push('/city/'+ tile_id);
        },
        fetchData() {
            this.userData = this.store.getUserData;
            if (this.userData.tutorial_level === 0) {
                this.isTutorialLevelZero = true;
            } else {
                this.isTutorialLevelZero = false;
            }
            this.fetchCityData(this.userData.city.id);
            this.maxWorkers = this.setMaxWorkers();
            this.isLoading = false;
        },
        setMaxWorkers() {
            if (this.userData && this.userData.city) {
                const workingCitizens = this.userData.city.workingCitizens;
                const citizens = this.userData.city.citizens;
                const sleepCitizens = citizens - workingCitizens;

                if (sleepCitizens > 0 && this.userData.city.work_fields > workingCitizens) {
                    return citizens - workingCitizens;
                }
            }
            return 0;
        },
        async fetchCityData(cityId) {
            await API.get(`/city/${cityId}/details`)
                .then(response => {
                    this.store.setCityData(response.data.data);
                })
                .catch(error => {
                    console.error('Error fetching city data:', error);
                });
        },
    },
    created() {
        this.store = usePiniaStore();
    },
    mounted() {
        this.fetchData();
    },
    watch: {
        'userData.tutorial_level'(newValue) {
            this.isTutorialLevelZero = newValue === 0;
        }
    }
};
</script>

<style scoped>
.production_page {
    width: 100%;
    height: 100%;
    position: relative;
    background: url('@/assets/images/production_page.png') no-repeat center center fixed;
    background-size: cover;
}
.production_description_area {
    position: relative;
    background: rgba(0, 0, 0, .7);
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    color: greenyellow;
}

.city {
    cursor: pointer;
    margin: 0 auto;
    width: 10%;
    height: 10%;
    position: absolute;
    bottom: 20px;
    left: 50%;
    background-color: white;
}
</style>
