<template>
  <!-- Przycisk otwierający chat -->
  <div class="chat_toggler" @click="toggleChat">
    {{ $t('app.wiadomosci') }}
  </div>

  <!-- Wyświetl całą zawartość chatu tylko po kliknięciu -->
  <div v-if="chatIsVisible" class="chat_container">
    <div class="chat_area_header">
      {{ $t('app.wiadomosci') }}
      <div class="close_button" @click="toggleChat" v-tooltip :tooltip="$t('app.zamknij')">x</div>
    </div>
    <div class="chat_area">
      <!-- Lista kanałów -->
      <div class="channel-list">
        <button @click="switchChannel('global')"
                :class="{ active: currentChannelType === 'global' && currentChannel === null }">
          {{ $t('app.chat_globalny') }} <span v-if="hasNewMessages('global')"> (n)</span>
        </button>
        <button v-if="gildiaId" @click="switchChannel('gildia')"
                :class="{ active: currentChannelType === 'gildia' && currentChannel === null }">
          {{ $t('app.chat_bractwa') }} <span v-if="hasNewMessages('gildia_' + gildiaId)"> (n)</span>
        </button>

        <!-- Prywatne kanały w formie listy rozwijanej -->
        <div class="private-channels">
          <h4>{{ $t('app.prywatne_kanaly') }}</h4>
          <div v-for="channel in privateChannels" :key="channel.channel">
            <button @click="switchToPrivateChat(channel.channel)"
                    :class="{ active: currentChannelType === 'private' && currentChannel === channel.channel }">
              {{ channel.name }}
              <span v-if="hasNewMessages(channel.channel)"> (n) </span>
            </button>
          </div>
        </div>
      </div>

      <!-- Okno chatu -->
      <div class="chat-window" ref="chatWindow">
        <div
          v-for="(message, index) in messages"
          :key="message.timestamp"
          :class="['message', isMyMessage(message) ? 'my-message' : 'other-message']"
          :ref="index === messages.length - 1 ? 'lastMessage' : null"
        >
          <div v-if="!isMyMessage(message)" class="message-info">
            <strong>{{ message.username }}</strong>
          </div>
          <div class="message-content">
            {{ message.message }}
          </div>
          <div class="message-time">
            <small>{{ formatDate(message.timestamp) }}</small>
          </div>
        </div>
      </div>
      <input class="input_chat_sender" v-model="newMessage" @keyup.enter="sendMessage" :placeholder="$t('app.wpisz_wiadomosc')"/>
    </div>
  </div>
</template>

<script>
import API from "@/utils/axios";
import { usePiniaStore } from '@/store/store';

export default {
    props: ['user_id'],
    name: "ChatComponent",
    data() {
        return {
            messages: [],
            globalMessages: [], // Wiadomości globalne
            gildiaMessages: [], // Wiadomości gildii
            privateMessages: {}, // Wiadomości prywatne przechowywane w obiekcie
            newMessage: '',
            currentChannelType: 'global', // Domyślny kanał to globalny
            currentChannel: null, // Przechowuje ID aktualnie wybranego kanału
            privateChannels: [], // Lista prywatnych kanałów
            newMessages: {}, // Przechowuje informacje o nowych wiadomościach w kanałach
            chatIsVisible: false,
        };
    },
    computed: {
        // Uzyskaj dostęp do gildiaId z Pinia
        gildiaId() {
            const store = usePiniaStore();
            console.log('userData', store.userData);
            return store.userData.gildiaId;
        }
    },
    mounted() {
        this.joinChannels();
        this.switchChannel('global');
        // Pobierz listę prywatnych kanałów
        this.loadPrivateChannels();

        // Nasłuchuj na wiadomości globalne
        this.$socket.on('global', (data) => {
            console.log('Odebrano wiadomość globalną:', data);
            this.globalMessages.push(data);
            if (this.currentChannelType !== 'global') {
                this.newMessages['global'] = true;
            }
        });

        // Nasłuchuj na wiadomości z gildii
        this.$socket.on('gildiaMessage', (data) => {
            if (this.gildiaId === data.channel) {
                console.log('Odebrano wiadomość z gildii:', data);
                this.gildiaMessages.push(data);
                if (this.currentChannelType !== 'gildia') {
                    this.newMessages[`gildia_${data.channel}`] = true;
                }
            }
        });

        // Nasłuchuj na prywatne wiadomości
        this.$socket.on('privateMessage', (data) => {
            if (!this.privateMessages[data.channel]) {
                this.privateMessages[data.channel] = [];
            }
            this.privateMessages[data.channel].push(data);
            if (this.currentChannel !== data.channel) {
                this.newMessages[data.channel] = true;
            }
        });
    },
    methods: {
        isMyMessage(message) {
            return message.user_id === this.user_id;
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            return date.toLocaleString(); // Formatowanie daty w czytelny sposób
        },
        loadPrivateChannels() {
            API.get('getPrivateChannels')
                .then(response => {
                    this.privateChannels = response.data;
                })
                .catch(error => {
                    console.error('Błąd podczas pobierania prywatnych kanałów:', error);
                });
        },
        switchChannel(type) {
            this.currentChannelType = type;
            this.newMessages[type] = false;
            this.currentChannel = null; // Resetuje kanał, jeśli zmieniamy na globalny lub gildii
            this.loadMessages(type); // Przekazujemy typ kanału
        },
        switchToPrivateChat(channel) {
            this.currentChannelType = 'private';
            this.currentChannel = channel;
            this.loadMessages('private'); // Przekazujemy typ kanału
            this.newMessages[channel] = false; // Oznaczamy wiadomości jako przeczytane po otwarciu kanału
        },
        getChannelId() {
            if (this.currentChannelType === 'global') {
                return 1; // Globalny kanał
            } else if (this.currentChannelType === 'gildia') {
                return this.gildiaId; // ID gildii
            } else if (this.currentChannelType === 'private') {
                return this.currentChannel; // Kanał prywatny (np. private_37)
            }
        },
        joinChannels() {
            if (this.gildiaId) {
                // Dołącz do kanału swojej gildii
                this.$socket.emit('joinChannel', `gildia_${this.gildiaId}`);
            }

            // Dołącz do swojego prywatnego kanału
            this.$socket.emit('joinChannel', `user_${this.user_id}`);
        },
        hasNewMessages(channel) {
            return this.newMessages[channel];
        },
        async loadMessages(channelType) {
            try {
                const response = await API.get('getMessages', {
                    params: {
                        channel: this.getChannelId(),
                        channel_type: channelType
                    }
                });
                console.log('messages=', response.data);
                this.gildiaId = response.data.gildiaId;
                if (channelType === 'global') {
                    this.globalMessages = response.data.messages || [];
                    this.messages = this.globalMessages;
                } else if (channelType === 'gildia') {
                    this.gildiaMessages = response.data.messages || [];
                    this.messages = this.gildiaMessages;
                } else if (channelType === 'private') {
                    this.privateMessages[this.getChannelId()] = response.data.messages || [];
                    this.messages = this.privateMessages[this.getChannelId()] || [];
                }
                this.scrollToBottom();
            } catch (error) {
                console.error('Błąd podczas ładowania wiadomości:', error);
            }
        },
        getPrivateChannelId() {
            return this.currentChannel; // Aktualnie wybrany prywatny kanał
        },
        sendMessage() {
            if (this.newMessage.trim() === '') return;

            const messageData = {
                channel_type: this.currentChannelType,
                message: this.newMessage,
                channel: this.getChannelId(), // Teraz przesyłamy tylko numer kanału
            };

            // Wysyłanie wiadomości do backendu Laravel
            API.post('sendMessage', messageData).then(() => {
                this.newMessage = '';
                this.scrollToBottom();
            });
        },
        toggleChat() {
            this.chatIsVisible = !this.chatIsVisible;
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const chatWindow = this.$refs.chatWindow;
                if (chatWindow) {
                    const start = chatWindow.scrollTop;
                    const end = chatWindow.scrollHeight;
                    const duration = 300; // Czas trwania w ms
                    const startTime = performance.now();

                    const animateScroll = (time) => {
                        const elapsed = time - startTime;
                        const progress = Math.min(elapsed / duration, 1);
                        chatWindow.scrollTop = start + (end - start) * progress;

                        if (progress < 1) {
                            requestAnimationFrame(animateScroll);
                        }
                    };

                    requestAnimationFrame(animateScroll);
                }
            });
        },
    },
};
</script>

<style scoped>

.chat-window {
    background-color: #fff;
    overflow-y: auto;
    padding: 10px;
    width: 70%;
    height: 80vh;
    position: relative;
    display: flex;
    flex-direction: column;
    color: black;
}

.chat_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90vh;
    width: 55vw;
    z-index: 9;
    background: rgba(0, 0, 0, .6);
    border: 2px solid #daa520;
    border-radius: 5px;
}

.chat_area {
    display: flex;
    gap: 20px;
    font-size: 12px;
}

.channel-list {
    display: flex;
    flex-direction: column;
}

.active {
    background-color: #f0f0f0;
    font-weight: bold;
}

.input_chat_sender {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.private-channels {
    margin-top: 10px;
    height: 280px;
    overflow: hidden auto;
}

.message {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    max-width: 70%;
    word-wrap: break-word;
}

.my-message {
    align-self: flex-start;
    background-color: #e0f7fa;
    padding: 8px;
    border-radius: 8px 8px 8px 0;
}

.other-message {
    align-self: flex-end;
    background-color: #c5e1a5;
    padding: 8px;
    border-radius: 8px 8px 0 8px;
}

.message-info {
    font-size: 0.85em;
    color: #555;
    margin-bottom: 3px;
}

.message-time {
    font-size: 0.75em;
    color: #999;
    align-self: flex-end;
}

.chat_toggler {
    position: fixed;
    bottom: 5px;
    cursor: pointer;
    padding: 2px 10px 2px 10px;
    font-size: 18px;
    left: 5px;
    color: #adff2f;
    font-family: Pirata One, cursive;
    background: rgba(0, 0, 0, .6);
    border: 2px solid #daa520;
    border-radius: 5px;
    transition: background-color .3s, color .3s, transform .2s;
    text-shadow: 1px 1px 2px #000;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
}

.chat_toggler:hover {
    background-color: #333;
    color: #FFD700;
    transform: translateY(-2px);
}

</style>
