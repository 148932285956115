<template>
    <div class="marketplace">
        <h2>{{ $t('app.gielda_ofert') }}</h2>
        <div v-if="offers.length" class="offers-area">
            <table>
                <thead>
                    <tr>
                        <th>{{ $t('app.produkt') }}</th>
                        <th>{{ $t('app.koordynaty_dostawy') }}</th>
                        <th>{{ $t('app.ilosc') }}</th>
                        <th>{{ $t('app.zarobek') }}</th>
                        <th>{{ $t('app.akcje') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="offer in offers" :key="offer.id" class="offer-item">
                        <td>{{ $t('products.product_' + offer.product_id) }}</td>
                        <td>{{ offer.column }}x{{ offer.row }}</td>
                        <td>{{ offer.quantity }}</td>
                        <td>{{ (offer.price * offer.quantity) }}</td>
                        <td><button @click="acceptOffer(offer.id)">{{ $t('app.realizuje_dostawe') }}</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="message" class="offers-area">
            <p v-html="message"></p>
        </div>
        <div class="toMap" v-tooltip :tooltip="$t('app.mapa')" @click="goToMap">&larr; {{ $t('app.do_mapy') }}</div>
    </div>
</template>

<script>
import API from "@/utils/axios";

export default {
    name: "MarketplaceComponent.vue",
    data() {
        return {
            offer_id: this.$route.params.offer_id,
            message: null,
            offers: []
        };
    },
    created() {
        this.fetchOffers();
    },
    methods: {
        async fetchOffers() {
            try {
                if (this.offer_id) {
                    // Pobierz pojedynczą ofertę
                    const response = await API.get(`/offers/${this.offer_id}`);
                    this.offers = response.data && response.data.offer ? [response.data.offer] : [];
                    this.message = response.data.message;
                } else {
                    // Pobierz wszystkie oferty
                    const response = await API.get('/offers');
                    this.offers = response.data.offers;
                }
            } catch (error) {
                console.error("Błąd podczas pobierania ofert:", error);
            }
        },
        async acceptOffer(offerId) {
            try {
                await API.post(`/orders/accept/${offerId}`);
                this.message = this.$t('app.zadanie_zostalo_przyjete_do_realizacji');
                await this.fetchOffers();
            } catch (error) {
                console.error("Błąd przy akceptacji oferty:", error);
            }
        },
        goToMap() {
            this.$router.push({ path: '/map', query: { center: 'ship' } });
        },
    }
};
</script>

<style scoped>
.marketplace {
    width: 100%;
    height: 100%;
    position: relative;
    background: url('@/assets/images/stats_page.png') no-repeat center center fixed;
    background-size: cover;
}

.offers-area {
    position: absolute;
    left: 50%;
    top: 100px;
    width: 45%;
    height: 100%;
    color: greenyellow;
    font-family: 'Montserrat', sans-serif;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, .7);
}

.offer-item {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
}

table {
    width: 100%;
}

.offers-area tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.1);
}

.offers-area tr:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

button {
    background-color: #5c8a8a;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

button:hover {
    background-color: #457575;
}
</style>
