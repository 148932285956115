<template>
    <div class="bg">
        <div class="description_area_top">
            <div class="desc_and_gallery">
                <p v-html="$t('app.login_page_description')"></p>
            </div>
            <div class="login-container">
                <router-link :to="{ path: '/' }" class="nav-link login" active-class="active">{{ $t('app.logowanie') }}</router-link>
                <router-link :to="{ path: '/register' }" class="nav-link register" active-class="active">{{ $t('app.rejestracja') }}</router-link>
                <form @submit.prevent="loginUser">
                    <input type="email" class="form-control" v-model="email" :placeholder="$t('app.podaj_email')">
                    <input type="password" class="form-control" v-model="password" :placeholder="$t('app.podaj_haslo')">
                    <button type="submit" class="btn btn-primary w-100 my-3 shadow">{{ $t('app.zaloguj') }}</button><br>
                    <router-link to="/reset-password" class="float-end">{{ $t('app.przypomnienie_hasla') }}</router-link>
                </form>
            </div>
            <div v-if="errors.length" class="error-messages">
                <div v-for="(error, index) in errors" :key="index" class="error">{{ error }}</div>
            </div>
        </div>
        <div class="description_area_bottom">
            <h1>Zrzuty ekranów gry</h1>
            <GalleryComponent />
        </div>
    </div>
    <div class="logo_area">
        <a href="https://www.miccom-games.pl" title="Miccom Games- studio produkcji gier"><img src="/images/miccom_games.jpg" alt="Miccom Games- studio produkcji gier"/></a>
    </div>
</template>

<script>
import API from "@/utils/axios";
import { useHead } from '@vueuse/head';
import {usePiniaStore} from "@/store/store";
import GalleryComponent from '@/components/GalleryComponent.vue';

export default {
    components: {
        GalleryComponent,
    },
    data() {
        return {
            email: '',
            password: '',
            errors: []
        };
    },
    created() {
        this.store = usePiniaStore();
        if (this.isUserLoggedIn()) {
            this.$router.push('/map');
        }
    },
    methods: {
        isUserLoggedIn() {
            const token = sessionStorage.getItem('token');
            return token && token !== '';
        },
        async loginUser() {
            this.errors = [];
            try {
                const response = await API.post(`/login`, {
                    email: this.email,
                    password: this.password
                });

                const token = response.data.token;
                sessionStorage.setItem('token', token);

                const userData = response.data.userData;
                this.store.setUserData(userData);
                if (userData.lang) {
                    await this.store.fetchTranslations(userData.lang).then(() => {
                        this.$i18n.setLocaleMessage(userData.lang, this.store.langTranslations);
                        this.$i18n.locale = userData.lang;
                    });
                }
                await this.store.fetchTechnologyTree();
                this.store.setShowChat(userData.id);

                this.$router.push('/map');
            } catch (error) {
                if (error.response && error.response.data && error.response.data.error) {
                    this.errors = [error.response.data.error];
                } else {
                    this.errors = ['Wystąpił nieoczekiwany błąd'];
                }
            }
        },
        updateMetaTags() {
            useHead({
                title: this.$t('app.title_loginForm'),
                meta: [
                    {
                        name: 'description',
                        content: this.$t('app.meta_description_loginForm'),
                    },
                    {
                        name: 'keywords',
                        content: this.$t('app.meta_keywords_loginForm'),
                    },
                ],
            });
        }
    },
    async mounted() {
        this.updateMetaTags();
    },
    watch: {
        '$i18n.locale'() {
            this.updateMetaTags();
        }
    }
};
</script>

<style scoped>
body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}

h1 {
    color: black;
}

h2 {
    margin: 0;
    font-size: 20px;
    color: black;
}

.bg {
    background-image: url(@/assets/images/background.jpg);
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.description_area_top {
    margin-top: 20px;
    width: 80%;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 15px 15px 0 0;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: center;
    gap: 20px;
}

.description_area_bottom {
    width: 80%;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0 0 15px 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    color: black;
}

.desc_and_gallery {
    width: 70%;
}

.login-container {
    width: 300px;
}

input[type="email"], input[type="password"] {
    width: 100%;
    padding: 5px;
    margin: 5px 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 20px;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #5c8a8a;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #457575;
}

.login {
    float: left;
}

.register {
    float: right;
}

.error-messages {
    color: red;
    background-color: #f8d7da;
    width: 100%;
    border: 1px solid #f5c6cb;
    position: fixed;
}

.error {
    margin: 5px 0;
}
</style>
