<template>
    <div class="bg">
        <div class="login-container">
            <router-link :to="{ path: '/' }" class="nav-link login" active-class="active">{{ $t('app.logowanie') }}</router-link>
            <router-link :to="{ path: '/register' }" class="nav-link register" active-class="active">{{ $t('app.rejestracja') }}</router-link>
            <form @submit.prevent="registerUser">
                <input type="text" class="form-control" v-model="username" :placeholder="$t('app.username')">
                <input type="email" class="form-control" v-model="email" :placeholder="$t('app.email')">
                <input type="password" class="form-control" v-model="password" :placeholder="$t('app.password')">
                <button type="submit" class="btn btn-primary w-100 my-3 shadow" :disabled="isSubmitting">{{ $t('app.register_button') }}</button>
                <div v-if="successMessage">
                    <p v-html="successMessage"></p>
                </div>
            </form>
        </div>
    </div>
    <div class="logo_area">
        <a href="https://www.miccom-games.pl" title="Miccom Games- studio produkcji gier"><img src="/images/miccom_games.jpg" alt="Miccom Games- studio produkcji gier"/></a>
    </div>
</template>

<script>
import API from "@/utils/axios";
import {useHead} from "@vueuse/head";

export default {
    data() {
        return {
            email: '',
            username: '',
            password: '',
            errors: [],
            successMessage: '',
            isSubmitting: false
        };
    },
    methods: {
        async registerUser() {
            if (this.isSubmitting) return;

            this.isSubmitting = true;

            await API.post(`/register`, {
                email: this.email,
                username: this.username,
                password: this.password
            })
            .then(response => {
                if (response && response.data && response.data.message) {
                    this.successMessage = response.data.message;

                    this.email = '';
                    this.username = '';
                    this.password = '';
                }
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.errors = [error.response.data.message];
                } else {
                    console.error('Błąd rejestracji:', error);
                    this.errors = ['Wystąpił nieoczekiwany błąd podczas rejestracji. Spróbuj ponownie później.'];
                }
            })
            .finally(() => {
                this.isSubmitting = false;
            });
        },
        updateMetaTags() {
            useHead({
                title: this.$t('app.title_registerForm'),
                meta: [
                    {
                        name: 'description',
                        content: this.$t('app.meta_description_registerForm'),
                    },
                    {
                        name: 'keywords',
                        content: this.$t('app.meta_keywords_registerForm'),
                    },
                ],
            });
        }
    },
    async mounted() {
        this.updateMetaTags();
    },
    watch: {
        '$i18n.locale'() {
            this.updateMetaTags();
        }
    }
};

</script>
<style scoped>
body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}
h2 {
    margin: 0;
    font-size: 20px;
    color: black;
}
.bg {
    background-image: url(@/assets/images/background.jpg);
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}


input[type="email"], input[type="text"], input[type="password"] {
    width: 100%;
    padding: 5px;
    margin: 5px 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 20px;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #5c8a8a;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #457575;
}
.error-message {
  color: red;
  font-weight: bold;
}

.login {
    float: left;
}
.register {
    float: right;
}
</style>
