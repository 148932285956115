<template>
    <div v-if="maxWorkers > 0">
        <form class="assign-workers-form" @submit.prevent="assignWorkers">
            <input type="number" min="0" step="10" :max="maxWorkers" v-model="citizensToWork" @input="adjustValue" @change="handleChange" />
            {{ $t('app.osob_przydzielam_do') }}
            <select v-model="product_id" @change="handleChange">
                <option :value="0" disabled>{{ $t('app.wybierz_rodzaj_pracy') }}</option>
                <option v-for="product_id in getProductRange(userData.producer)" :key="product_id" :value="product_id">
                    {{ $t('products.product_' + product_id) }}
                </option>
            </select><br><br>
            {{ $t('app.beda_pracowac') }}:
            <select v-model="work_time" @change="handleChange">
                <option :value="0" disabled>{{ $t('app.wybierz_czas_pracy') }}</option>
                <option :value="1">{{ $t('app.one_hour') }}</option>
                <option :value="2">{{ $t('app.two_hour') }}</option>
                <option :value="8">{{ $t('app.eight_hour') }}</option>
                <option :value="16">{{ $t('app.sixteen_hour') }}</option>
            </select><br>
            <input type="checkbox" v-model="continueCheckbox" :value="1" /> {{ $t('app.kontynuuj_az_zakocze') }}<br>
            <button @click="submitForm">{{ $t('app.zatwierdz_produkcje') }}</button>

            <!-- Modal -->
            <modal v-if="showModal">
                <div class="modal-content">
                    <span class="close_button" @click="showModal = false" v-tooltip :tooltip="$t('app.zamknij')">&times;</span>
                    <p>{{ $t('app.zaznaczenie_automatycznego_przedluzenia_produkcji_kosztuje_zlota') }}!</p>
                    <button @click="addWithContinuationAndClose">{{ $t('app.tak_place_zlotem_przedluzaj_az_zakoncze') }}</button>
                    <button @click="addWithoutContinuation">{{ $t('app.dodaj_bez_kontynuacji') }}</button>
                </div>
            </modal>
        </form>
    </div>
    <div v-else>
        <br><br />
        {{ $t('app.wszyscy_pracownicy_pracuja') }}.<br />
    </div>
</template>
<script>

import API from "@/utils/axios";
import {usePiniaStore} from "@/store/store";

export default {
    name: "WorkersForm.vue",
    props: {
        userData: {
            type: Object,
            required: true
        },
        maxWorkers: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            responseText: '',
            showModal: false,
            citizensToWork: 0,
            work_time: 0,
            product_id: 0
        }
    },
    methods: {
        submitForm() {
            if (this.continueCheckbox) {
                this.addWithContinuation();
                this.showModal = true;
            } else {
                this.addWithoutContinuation();
            }
        },
        getProductRange(producer) {
            switch (producer) {
                case 1:
                    return [1, 2, 3, 4, 5, 6, 7, 22];
                case 2:
                    return [8, 9, 10, 11, 12, 13, 14, 22];
                case 3:
                    return [15, 16, 17, 18, 19, 20, 21, 22];
                default:
                    return [];
            }
        },
        handleChange() {
            this.checkWorkEffect();
        },
        addWithContinuation() {
            this.setProduction();
            this.showModal = false;
        },
        addWithoutContinuation() {
            this.continueCheckbox = 0;
            this.setProduction();
            this.showModal = false;
        },
        addWithContinuationAndClose() {
            this.addWithContinuation();
            this.showModal = false;
        },
        async checkWorkEffect() {
            try {
                if (this.citizensToWork && this.product_id && this.work_time) {

                    const dataParams = {
                        citizensToWork: this.citizensToWork,
                        product_id: this.product_id,
                        work_time: this.work_time
                    };

                    API.post(`/checkWorkEffect`, dataParams)
                    .then(response => {
                        this.responseText = response.data.message;
                        this.store.setMessage(this.responseText);
                    })
                    .catch(error => {
                        console.error('Błąd podczas wysyłania danych:', error);
                        this.responseText = this.$t('app.wystapil_blad_podczas_wysylania_danych');
                    });
                } else {
                    this.responseText = this.$t('app.musisz_wybrac_wszystkie_trzy_elementy');
                }
            } catch (error) {
                console.error('Błąd podczas wysyłania danych:', error);
                this.responseText = this.$t('app.wystapil_blad_podczas_wysylania_danych');
            }
        },
        adjustValue() {
            if (this.citizensToWork > this.maxWorkers) {
                this.citizensToWork = this.maxWorkers;
            }

            if (this.citizensToWork < 0) {
                this.citizensToWork = 0;
            }
        },
        setProduction() {
            try {
                if (this.citizensToWork && this.product_id && this.work_time) {
                    const dataParams = {
                        citizensToWork: this.citizensToWork,
                        product_id: this.product_id,
                        work_time: this.work_time,
                        continue: this.continueCheckbox
                    };

                    API.post(`/setProduction`, dataParams)
                    .then(response => {
                        this.responseText = response.data.message;
                        this.$emit('productionUpdated');
                    })
                    .catch(error => {
                        console.error('Błąd podczas wysyłania danych:', error);
                        this.responseText = this.$t('app.wystapil_blad_podczas_wysylania_danych');
                    });
                } else {
                    this.responseText = this.$t('app.musisz_wybrac_wszystkie_trzy_elementy');
                }
                this.store.setMessage(this.responseText);
            } catch (error) {
                console.error('Błąd podczas wysyłania danych:', error);
                this.responseText = this.$t('app.wystapil_blad_podczas_wysylania_danych');
            }
        }
    },
    created() {
        this.store = usePiniaStore();
    },
}
</script>

<style scoped>

.assign-workers-form input,
.assign-workers-form select {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 4px;
    width: auto;
    background-color: transparent;
    color: #adff2f;
}

.assign-workers-form select option {
    background: rgba(0, 0, 0, .7);
    color: #adff2f;
}
</style>
