<template>
    <div class="modal-overlay" @click.self="close">
        <div class="modal-content">
            <button class="close-button" @click="close">✖</button>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalProductBuyComponent",
    methods: {
        close() {
            this.$emit("close");
        }
    }
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 500px;
    width: 100%;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}
</style>
