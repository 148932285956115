<template>
    <div v-if="showPreloader" class="preloader">
        <div class="question_area">
            <div v-if="randomQuestion">
                <button v-if="showCloseButton" @click="closePreloader" class="close_button" v-tooltip :tooltip="$t('app.zamknij')">x</button>
                <p><strong>{{ randomQuestion.questionText }}</strong><br>
                    {{ $t('app.poprawna_odpowiedz_to') }}:<br> {{ randomQuestion.correctAnswer }}
                </p>
            </div>
            <div v-else>
                {{ $t('app.ladowanie_pytania') }}
            </div>
        </div>
    </div>
</template>

<script>
import {usePiniaStore} from "@/store/store";

export default {
    props: {
        isLoading: {
            type: Boolean,
            required: true
        },
        minimumLoadingTime: {
            type: Number,
            default: 5000
        }
    },
    data() {
        return {
            showPreloader: true,
            loadStartTime: null,
            randomQuestion: null,
            isAlreadyLoading: false,
            showCloseButton: false,
        };
    },
    watch: {
        isLoading(newVal) {
            if (newVal) {
                this.startLoading();
            } else {
                this.checkLoadingTime();
            }
        }
    },
    created() {
        this.store = usePiniaStore();
        this.startLoading();
    },
    methods: {
        startLoading() {
            if (this.isAlreadyLoading) return;
            this.isAlreadyLoading = true;
            this.loadStartTime = Date.now();
            this.randomQuestion = this.getRandomQuestion();
            this.showPreloader = true;
            this.showCloseButton = false;
        },
        checkLoadingTime() {
            const elapsedTime = Date.now() - this.loadStartTime;
            if (elapsedTime < this.minimumLoadingTime) {
                setTimeout(() => {
                    this.showCloseButton = true;
                }, this.minimumLoadingTime - elapsedTime);
            } else {
                this.showCloseButton = true;
            }
        },
        closePreloader() {
            this.showPreloader = false;
            this.isAlreadyLoading = false;
        },
        getRandomQuestion() {
            const questions = this.store.questions;
            if (questions && questions.length > 0) {
                const randomIndex = Math.floor(Math.random() * questions.length);
                const question = questions[randomIndex];
                const correctAnswer = question.answers.find(answer => answer.correct === true);
                return {
                    questionText: question.question,
                    correctAnswer: correctAnswer ? Object.values(correctAnswer)[0] : 'Brak odpowiedzi'
                };
            }
            return null;
        }
    }
}
</script>

<style>

.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    font-size: 20px;
    color: #000;
    text-align: center;
    z-index: 10;
    background: url('@/assets/images/preloader_page.png') no-repeat center center fixed;
    background-size: cover;
    display: flex;
    justify-content: space-between;
}

.question_area {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5em;
    z-index: 1000;
}

.question_area::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    filter: blur(10px);
    z-index: -1;
}

.question_area div {
    position: relative;
    z-index: 1;
    background: rgba(0, 0, 0, .5);
    padding: 45px;
    border-radius: 10px;
    max-width: 80%;
}

</style>
