<template>
    <div class="gallery-container">
        <!-- Swiper z miniaturami -->
        <swiper
            modules="[Autoplay]"
            :slides-per-view="5"
            :space-between="10"
            :autoplay="{ delay: 2000, disableOnInteraction: false }"
            :class="gallery-swiper"
        >
        <swiper-slide v-for="(image, index) in images" :key="index">
            <img
                :src="image.src"
                :alt="image.alt"
                class="thumbnail"
                @click="openLightbox(index)"
            />
            <p class="thumbnail-caption">{{ image.alt }}</p>
        </swiper-slide>
        </swiper>

        <!-- Lightbox -->
        <vue-easy-lightbox
            v-if="lightboxVisible"
            :visible="lightboxVisible"
            :imgs="images.map(image => image.src)"
            :index="lightboxIndex"
            @hide="lightboxVisible = false"
        />
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper"; // Import modułu automatycznego przewijania
import VueEasyLightbox from "vue-easy-lightbox";
import "swiper/swiper-bundle.css"; // Styl Swiper.js

export default {
    components: {
        Swiper,
        SwiperSlide,
        VueEasyLightbox,
    },
    data() {
        return {
            images: [
                { src: "/images/game_area.jpg", alt: "Plansza gry" },
                { src: "/images/game_with_pirate.jpg", alt: "Gra w kości z piratem" },
                { src: "/images/ship_deck.jpg", alt: "Pokład statku, trening załogi" },
                { src: "/images/shipyard.jpg", alt: "Stocznia - kup nowy, lepszy statek" },
                { src: "/images/shop.jpg", alt: "Sklep, rozładunek/załadunek towarów" },
                { src: "/images/city.png", alt: "Budynki w mieście" },
            ],
            lightboxVisible: false,
            lightboxIndex: 0,
        };
    },
    methods: {
        openLightbox(index) {
            this.lightboxIndex = index;
            this.lightboxVisible = true;
        },
    },
};
</script>

<style scoped>

.gallery-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
}

.gallery-thumbnails {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
}

.gallery-swiper {
    width: 90%;
    margin: 0 auto;
}

.thumbnail {
    width: 100%;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 8px;
    transition: transform 0.2s ease-in-out;
}

.thumbnail:hover {
    transform: scale(1.03);
}

.easy-lightbox {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999 !important;
}

.easy-lightbox img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.thumbnail-caption {
    font-size: 14px;
    color: #555;
    margin-top: 5px;
    text-align: center;
}
</style>
